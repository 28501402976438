.fullPageLoader-module__full-page-load___2Wyj8 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  opacity: .8;
  z-index: 10; }
  .fullPageLoader-module__full-page-load___2Wyj8 .sg-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
