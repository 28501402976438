.profileForm-module__readOnly___20ZUM[type=text] {
  color: rgba(120, 120, 120, 0.8); }

.profileForm-module__profileForm___3UJqA {
  padding: 0px 18px;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  box-sizing: border-box; }

.profileForm-module__row___38HqG {
  display: flex;
  flex-wrap: wrap; }
  .profileForm-module__row___38HqG .profileForm-module__col___iQBKT {
    width: 100%; }

.profileForm-module__submitButton___SWl5o {
  margin: 30px 0px;
  text-align: center; }

.profileForm-module__titleId___ebGsk,
.profileForm-module__industryId___1BBq7 {
  display: none; }

.profileForm-module__emailId___27814
.profileForm-module__firstNameId___1WEx7,
.profileForm-module__lastNameId___3cBNV,
.profileForm-module__companyNameId___1gqin {
  text-transform: capitalize; }
  @media screen and (min-width: 993px) {
    .profileForm-module__emailId___27814
.profileForm-module__firstNameId___1WEx7,
    .profileForm-module__lastNameId___3cBNV,
    .profileForm-module__companyNameId___1gqin {
      text-transform: none; } }

@media screen and (min-width: 768px) {
  .profileForm-module__row___38HqG {
    flex-wrap: nowrap; }
    .profileForm-module__row___38HqG .profileForm-module__col___iQBKT:not(:last-child) {
      padding-right: 12px; } }
