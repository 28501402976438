.MultiProductCard-module__multiProductCard___3LfRM {
  height: auto;
  color: #294661;
  background: white;
  box-shadow: 0px 2px 6px rgba(41, 70, 97, 0.15);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 13px;
  line-height: 20px; }

.MultiProductCard-module__titleRow___1XHeO {
  padding-bottom: 6px; }

.MultiProductCard-module__title___2R5kP {
  font-weight: bold;
  line-height: 18px; }

.MultiProductCard-module__strikePrice___1Kyu_ {
  font-style: italic;
  text-decoration: line-through;
  color: #546b81;
  padding-right: 6px; }

.MultiProductCard-module__priceWrapper___qgFfo {
  display: flex; }

.MultiProductCard-module__price___1Gi_d {
  font-weight: bold;
  color: #2A4660;
  text-align: right; }

.MultiProductCard-module__timeUnit___C6wI0 {
  font-weight: normal; }

.MultiProductCard-module__descriptionRow___3ESXy {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.MultiProductCard-module__descriptionRow___3ESXy:not(:last-child) {
  padding-bottom: 6px; }
