html {
  height: 100%; }

body {
  height: 100%;
  background: #fff;
  font-family: Colfax,Helvetica,Arial,sans-serif;
  font-display: auto; }
  body button, body input {
    font-family: Colfax,Helvetica,Arial,sans-serif; }

.sg-input-container {
  margin-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px; }

.signup-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%; }
  @media (min-width: 993px) {
    .signup-container {
      width: auto; } }
  .signup-container .btn {
    font-family: Colfax,Helvetica,Arial,sans-serif; }
  .signup-container .signup-title {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.5px;
    margin-bottom: 18px; }
    @media (min-width: 768px) {
      .signup-container .signup-title {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: -1px; } }
  .signup-container .modal-footer {
    border: 0; }

.signup-base-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch; }

.signup-loading-header {
  margin-top: 70px;
  text-align: center; }

.signup {
  margin: 30px auto;
  width: 100%;
  background-color: #FFFFFF; }
  @media (min-width: 993px) {
    .signup {
      width: 525px;
      margin: 120px auto;
      padding: 0 40px 0;
      display: inline-block; } }
  .signup .form-container {
    padding: 0 18px;
    max-width: 450px;
    box-sizing: content-box;
    margin: 0 auto; }
    @media (min-width: 993px) {
      .signup .form-container {
        padding: 0;
        max-width: auto; } }
  .signup .input-text-wrap {
    text-align: left; }
  .signup #input-text-cvv-popup {
    left: -40% !important;
    top: -80% !important; }
  .signup .billing-subheader {
    color: #294661;
    font-size: 16px;
    text-align: left;
    margin-top: 25px;
    margin-bottom: 5px; }
  .signup input {
    background-color: transparent;
    font-family: Colfax,Helvetica,Arial,sans-serif; }
  .signup .full-width-input-popup.right {
    margin-top: -36px !important;
    margin-left: -265px !important;
    left: 100% !important; }
  .signup gray-input {
    background-color: lightgray; }
  .signup li {
    list-style-type: none; }
  .signup .signup-dropdown {
    border-bottom: 1px solid rgba(41, 70, 97, 0.2);
    position: relative;
    background-color: transparent;
    transition: .5s border-color; }
    .signup .signup-dropdown label {
      font-size: 12px;
      top: -14px; }
    .signup .signup-dropdown.sg-input-container {
      margin-top: 21px; }
    .signup .signup-dropdown.signup-dropdown-error {
      border-color: #BB1928;
      border-width: 2px; }
  .signup .iconInputWrapper {
    position: relative; }
    .signup .iconInputWrapper input, .signup .iconInputWrapper select {
      border: none; }
    .signup .iconInputWrapper .inputIcon {
      content: "";
      width: 16px;
      height: 16px;
      top: 10px;
      left: 12px;
      position: absolute; }
    .signup .iconInputWrapper .colInputIcon {
      left: 28px; }
    .signup .iconInputWrapper .cvvIcon {
      background-image: url("/images/icons/cvv.svg"); }
    .signup .iconInputWrapper .contactInfoIcon {
      background-image: url("/images/icons/user-contact-info.svg"); }
    .signup .iconInputWrapper .creditCardIcon {
      background-image: url("/images/icons/credit-card.svg"); }
    .signup .iconInputWrapper .calendarIcon {
      background-image: url("/images/icons/calendar2.svg"); }
    .signup .iconInputWrapper .cartIcon {
      background-image: url("/images/icons/cart.svg");
      left: 11px; }
  .signup hr {
    width: 1000px; }
  .signup ::-webkit-input-placeholder {
    color: #666; }
  .signup :-moz-placeholder {
    color: #666; }
  .signup ::-moz-placeholder {
    color: #666; }
  .signup :-ms-input-placeholder {
    color: #666; }
  .signup h5 {
    size: 13px;
    color: #657581;
    line-height: 26px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0px; }
  .signup .header {
    margin-bottom: 10px; }
  .signup .signupSubtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px; }
    @media (min-width: 993px) {
      .signup .signupSubtitle {
        margin-bottom: 20px; } }
  .signup .inputWrap {
    margin: 8px 0px; }
  .signup .package-selector {
    border-radius: 4px;
    border-color: #D9D9D9;
    border-width: 1.5px;
    min-width: 170px; }
  .signup .btn label {
    cursor: pointer; }
  .signup .dropdown-menu {
    max-height: none; }
  .signup .cvv-tooltip {
    top: -25px;
    right: 5px;
    float: right;
    color: #666; }
  .signup .btn-account {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 200px; }
  .signup .plan-info {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #EEE;
    font-size: 14px; }
  .signup .plan-subinfo {
    margin-top: 0;
    padding: 10px;
    border-left: 1px solid #EEE;
    border-right: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
    font-size: 14px; }
  .signup .plan-selected {
    color: #999;
    text-transform: capitalize; }
  .signup .price {
    color: #666; }
  .signup .subtotal {
    color: #999; }
  .signup .couponWrap {
    display: none; }
  .signup .couponButton {
    color: #999;
    margin-top: 20px;
    cursor: pointer; }
  .signup .applyCouponButton {
    color: #2D7DDC;
    cursor: pointer; }
  .signup .loadingIconWrapper {
    text-align: center;
    display: none; }
  .signup .error {
    color: #BB1928;
    margin-bottom: 10px; }
  .signup .radio-error {
    margin-top: -10px; }
  .signup .globalErrors {
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    background-color: white;
    margin-top: 20px; }
    .signup .globalErrors .title {
      font-size: 16px;
      padding: 5px 15px;
      background-color: #C64E44;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: white; }
    .signup .globalErrors .warning {
      position: relative;
      bottom: 3px;
      right: 5px; }
    .signup .globalErrors .body {
      padding: 10px 15px;
      color: #C64E44; }

.signupThanks h2 {
  display: block;
  width: 100%; }

.signupThanks .verificationContainer {
  margin-top: 30px; }

.form-prompt {
  font-size: 14px;
  margin-bottom: 10px; }

.no-padding {
  padding: 0; }

.no-left-padding {
  padding-left: 0; }

.no-right-padding {
  padding-right: 0; }

.emailValidation h2 {
  margin-bottom: 10px; }

.emailValidation .inputTitle {
  text-align: left; }

.emailValidation .body .updateEmailBtn {
  width: 80%;
  height: 40px;
  font-size: 14px;
  padding-top: 12px;
  margin-bottom: 15px;
  margin-top: 5px; }

.emailValidation .body .success {
  color: #41896C; }

.emailValidation .body .failure {
  color: #C84D40; }

.emailValidation .logo {
  margin-top: 25px; }

.provision .input-text-info {
  display: block;
  text-align: left;
  min-height: 21px; }

.provision .icon-info-circle {
  color: #CCCCCC;
  vertical-align: middle; }

.provision .provision-headers h1, .provision .provision-headers h4 {
  text-align: left;
  padding: 0; }

.provision .provision-subheader {
  color: #294661;
  font-size: 16px;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 5px; }

.provision .tooltip-inner {
  text-align: left; }

.provision dl {
  margin: 0;
  padding: 0;
  clear: both; }

.provision .emailType {
  font-size: 14px;
  text-align: left; }
  .provision .emailType li {
    padding-top: 5px; }

.provision a {
  color: #2D7DDC; }

.provision .nameInput .inputWrap {
  display: inline;
  width: 48%; }

.provision .companyLocation .inputWrap {
  display: inline;
  width: 31%;
  margin-right: 10px; }

.provision .lastWrap {
  margin-bottom: 50px; }

.provision .errorContainer {
  text-align: left;
  padding-left: 10px; }

.provision .invalidInputBorder {
  margin-bottom: 10px; }

.signupLoading {
  text-align: center;
  padding: 50px;
  font-size: 25px;
  line-height: 25px; }

.unknownErrors {
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  background-color: white;
  margin-top: 20px; }
  .unknownErrors .title {
    font-size: 16px;
    padding: 5px 15px;
    background-color: #C64E44;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white; }
  .unknownErrors .warning {
    position: relative;
    bottom: 3px;
    right: 5px; }
  .unknownErrors .body {
    margin: 0px;
    padding: 10px 15px;
    color: #C64E44; }
    .unknownErrors .body dd {
      line-height: 20px; }

.signup-modal .modal-dialog {
  width: 640px; }
  .signup-modal .modal-dialog .modal-body {
    background-color: #FCFCFC;
    padding: 0px; }

.please-wait .title {
  font-size: 36px;
  text-align: center; }

.please-wait p {
  font-size: 24px;
  margin-bottom: 31px; }

.zuora-container {
  padding: 0 18px; }

iframe#z_hppm_iframe {
  height: 694px;
  max-width: 449px;
  width: 100%;
  margin: 0 auto;
  background: transparent; }
  @media (min-width: 768px) {
    iframe#z_hppm_iframe {
      max-width: 450px;
      height: 519px; } }
  @media (min-width: 993px) {
    iframe#z_hppm_iframe {
      width: 451px;
      max-width: unset;
      height: 484px; } }

.recaptcha-container {
  margin: 25px auto 0;
  text-align: center; }

.recaptcha-wrap {
  display: inline-block;
  margin-bottom: 0; }
  .recaptcha-wrap.error {
    border: 1px solid #b71c1c;
    padding: 10px 10px 7px; }

.secure-lock, .tos-info {
  margin-bottom: 30px;
  text-align: left;
  font-size: 13px;
  line-height: 18px;
  color: #657581; }
  @media (min-width: 993px) {
    .secure-lock, .tos-info {
      width: 450px;
      margin-bottom: 59px;
      text-align: center; } }

.secure-lock i {
  margin-top: 10px;
  display: inline-block;
  background-image: url("/images/icons/lock_green.svg");
  background-repeat: no-repeat;
  margin-right: 7px;
  height: 15px;
  width: 11px;
  vertical-align: bottom; }

.fallbackOkBtn {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.required-dot {
  font-size: 18px;
  color: #BB0000;
  position: relative;
  top: -2px; }

[data-tooltip]:after {
  width: 320px;
  white-space: normal; }

.paste a {
  color: #0263E0;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px; }

.paste h5 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px; }

.paste h6 a {
  font-weight: 500;
  font-size: 12px; }

.paste h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px; }
