.mobileproduct-module__mobileProduct___3p4-o {
  height: auto;
  background: #f3f9fd;
  font-size: 13px;
  line-height: 20px;
  border-top: 1px solid #e8f2fc;
  margin-bottom: 20px; }
  @media (min-width: 993px) {
    .mobileproduct-module__mobileProduct___3p4-o {
      display: none; } }
  .mobileproduct-module__mobileProduct___3p4-o p {
    margin: 0; }

.mobileproduct-module__productToggle___Up3j- {
  cursor: pointer;
  border-bottom: 1px solid #e8f2fc;
  padding: 0 18px; }

.mobileproduct-module__productToggleContainer___24SED {
  display: flex;
  align-items: center;
  max-width: 450px;
  margin: 0 auto;
  padding: 18px 0; }

.mobileproduct-module__productSummary___f_8Gc {
  max-width: 450px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 18px;
  border-bottom: 1px solid #e8f2fc; }

.mobileproduct-module__toggleTitle___24pgq {
  flex: 0 0 50%;
  color: #1a82e2;
  font-weight: 600; }

.mobileproduct-module__invertedCaret___2UP2P {
  transform: rotate(180deg) scaleX(-1);
  display: inline-block; }

.mobileproduct-module__toggleTotal___35X6w {
  flex: 0 0 50%;
  text-align: right; }
  .mobileproduct-module__toggleTotal___35X6w p {
    font-size: 15px;
    line-height: 20px; }
  .mobileproduct-module__toggleTotal___35X6w span {
    font-size: 11px;
    line-height: 15px; }
