@charset "UTF-8";
.jobsToBeDoneCard-module__jobs-to-be-done-container___3XuPd {
  display: flex;
  justify-content: center; }

.jobsToBeDoneCard-module__jobs-to-be-done-card___bKLTv {
  min-width: 410px;
  margin-top: 90px;
  box-shadow: 0 2px 6px 0 rgba(41, 70, 97, 0.15);
  padding: 40px; }
  .jobsToBeDoneCard-module__jobs-to-be-done-card___bKLTv h2 {
    margin-bottom: 2px; }
  .jobsToBeDoneCard-module__jobs-to-be-done-card___bKLTv p {
    color: #546b81;
    font-size: 15px; }
  .jobsToBeDoneCard-module__jobs-to-be-done-card___bKLTv li {
    margin-bottom: 10px; }
  .jobsToBeDoneCard-module__jobs-to-be-done-card___bKLTv li::before {
    font-family: 'styleguideicons';
    color: #18c96e;
    content: "";
    margin-right: 6px; }

.jobsToBeDoneCard-module__jobs-to-be-done-img___2ESx2 {
  position: absolute;
  right: -15px;
  top: -75px; }
