.multisidebar-module__sidenav___o5dfH {
  background: #F6FAFD;
  display: none; }
  @media (min-width: 993px) {
    .multisidebar-module__sidenav___o5dfH {
      top: 0;
      right: 0;
      width: 560px;
      min-height: 100vh;
      display: block; } }

.multisidebar-module__sidenavContainer___3QO86 {
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 208px;
  color: #294661; }
  @media (min-width: 1021px) {
    .multisidebar-module__sidenavContainer___3QO86 {
      margin-left: 105px;
      margin-right: 105px; } }

.multisidebar-module__noProductCard___2ajvS {
  height: auto;
  color: #294661;
  background: white;
  box-shadow: 0px 2px 6px rgba(41, 70, 97, 0.15);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 13px;
  line-height: 20px; }
