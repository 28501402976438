.layout-module__signup-alert___1f3gh {
  padding: 12px; }
  @media (min-width: 993px) {
    .layout-module__signup-alert___1f3gh {
      padding: 20px 0; } }
  .layout-module__signup-alert___1f3gh p {
    margin-left: 0;
    max-width: 100%;
    text-align: left; }
    @media (min-width: 993px) {
      .layout-module__signup-alert___1f3gh p {
        margin-left: 40px;
        max-width: 80%;
        text-align: center; } }
  .layout-module__signup-alert___1f3gh i.layout-module__sg-icon-check-circle___mZ_lk {
    display: none; }
    @media (min-width: 993px) {
      .layout-module__signup-alert___1f3gh i.layout-module__sg-icon-check-circle___mZ_lk {
        display: block; } }
