.betaBadge-module__betaBeta___1Ovr9 {
  display: inline-block;
  padding: 3px 6px;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 1px;
  color: #FFFFFF;
  text-transform: uppercase;
  vertical-align: middle;
  background-color: #a9b5c0;
  border-radius: 2px;
  background-image: linear-gradient(125deg, #dea7e8 0, #7c4dff 100%); }
  .betaBadge-module__betaBeta___1Ovr9.betaBadge-module__isSmall___2qWnd {
    padding: 2px 3px 1px;
    font-size: 9px;
    letter-spacing: 0.5px; }
