.header-module__hidden___2WNNn {
  display: none; }

.header-module__show___19OM7 {
  display: inline;
  position: 'relative';
  left: '250px';
  top: '30px'; }

.header-module__signup-header___kWxre {
  padding: 0 18px;
  max-width: 450px;
  box-sizing: content-box;
  margin: 0 auto; }
  @media (min-width: 993px) {
    .header-module__signup-header___kWxre {
      max-width: auto; } }

.header-module__header-table___3JuLa {
  width: 100%; }

.header-module__header-logo___2hrDl {
  position: 'absolute';
  top: '85px'; }

.header-module__signup-top___1R-fm {
  display: flex;
  justify-content: space-between; }

.signupSubtitle {
  font-size: 18px; }
