.summarycard-module__summarycard___2Fezc {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 203px;
  height: auto;
  color: #294661;
  background: white;
  width: 320px;
  top: 150px;
  box-shadow: 0px 2px 6px rgba(41, 70, 97, 0.15);
  border-radius: 2px; }
