.totalcard-module__totalCard___2Km2L {
  height: auto;
  color: white;
  background: #294661;
  box-shadow: 0px 2px 6px rgba(41, 70, 97, 0.15);
  border-radius: 2px;
  padding: 20px;
  font-size: 13px; }
  @media (min-width: 993px) {
    .totalcard-module__totalCard___2Km2L {
      margin-bottom: 10px; } }

.totalcard-module__titleRow___2V9xD {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
  justify-content: space-between; }

.totalcard-module__title___1GQjf {
  font-weight: bold;
  width: 60%; }

.totalcard-module__price___3DiD8 {
  font-weight: 600;
  font-size: 18px;
  text-align: right;
  line-height: 27px; }
  .totalcard-module__price___3DiD8 span {
    font-weight: normal; }

.totalcard-module__description___2e4GR {
  color: rgba(255, 255, 255, 0.8);
  line-height: 20px; }
  .totalcard-module__description___2e4GR p {
    font-size: 13px;
    margin: 0;
    line-height: 20px; }
    .totalcard-module__description___2e4GR p .totalcard-module__proratedPrice___1v7C5 {
      font-weight: bold; }
  .totalcard-module__description___2e4GR a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline; }

.totalcard-module__taxDisclaimer___3Ey_Z {
  font-size: 13px;
  line-height: 20px;
  color: #546b81;
  text-align: right;
  display: none; }
  @media (min-width: 993px) {
    .totalcard-module__taxDisclaimer___3Ey_Z {
      display: block; } }
