.addoncard-module__addOnCard___12T5c {
  height: auto;
  color: #294661;
  background: white;
  box-shadow: 0px 2px 6px rgba(41, 70, 97, 0.15);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 20px;
  font-size: 13px;
  line-height: 20px; }

.addoncard-module__titleRow___bx-bt {
  padding-bottom: 6px;
  font-weight: bold;
  line-height: 18px; }

.addoncard-module__itemRow___mAecz {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.addoncard-module__itemPrice___22huL span {
  font-weight: bold; }

.addoncard-module__addOnRow___1QAQE:not(:last-child) {
  padding-bottom: 6px; }
