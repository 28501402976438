.adsSummaryCard-module__adsInfoContainer___3lfyy {
  padding: 20px; }
  .adsSummaryCard-module__adsInfoContainer___3lfyy .adsSummaryCard-module__adsInfoHeading___130nC {
    font-size: 20px;
    font-weight: 600; }
  .adsSummaryCard-module__adsInfoContainer___3lfyy .adsSummaryCard-module__adsInfo___1hqw0 {
    font-size: 13px;
    color: #294661;
    line-height: 20px; }
  .adsSummaryCard-module__adsInfoContainer___3lfyy a {
    font-size: 13px; }
  .adsSummaryCard-module__adsInfoContainer___3lfyy .adsSummaryCard-module__bolded___2stIV {
    font-weight: bold; }
